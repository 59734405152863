export const debounce = <T>(fn, ms = 0) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    return new Promise<T>((resolve, reject) => {
      timer = setTimeout(() => {
        try {
          resolve(fn.apply(this, args));
        } catch (e) {
          reject(e);
        }
      }, ms);
    });
  };
};

export const getParams = () => {
  // eslint-disable-next-line no-restricted-globals
  const search = location.search.substring(1);

  if (!search) {
    return {};
  }

  return JSON.parse(
    `{"${search.replace(/&/g, '","')
      .replace(/=/g, '":"')}"}`,
    (key, value) => (key === '' ? value : decodeURIComponent(value)),
  );
};

export const waitBefore = (ms: number) => new Promise<void>((resolve) => setTimeout(resolve, ms));

// eslint-disable-next-line no-restricted-globals
export const getBase = () => [location.protocol, '//', location.host, location.pathname].join('');
