import * as oauth2 from './oauth';

const PROFILE_STORE_KEY = 'user_profile';
const REDIRECT_TO_KEY = 'redirect_to';

export const hasAnyRole = (...roles) => {
  const user = readProfile();
  return user && roles.includes(user.role);
};

export const writeRedirectTo = (url) => {
  localStorage.setItem(REDIRECT_TO_KEY, JSON.stringify(url));
};

export const popRedirectTo = () => {
  const item = localStorage.getItem(REDIRECT_TO_KEY);

  if (item === null) {
    return null;
  }

  localStorage.removeItem(REDIRECT_TO_KEY);

  return JSON.parse(item);
};

export const writeProfile = (profile) => {
  localStorage.setItem(PROFILE_STORE_KEY, JSON.stringify(profile));
};

export const readProfile = () => {
  const item = localStorage.getItem(PROFILE_STORE_KEY);

  if (item === null) {
    return null;
  }

  return JSON.parse(item);
};

export const isLogged = () => oauth2.readToken()?.accessToken !== undefined;

export const logout = () => {
  localStorage.removeItem(PROFILE_STORE_KEY);
  oauth2.popToken();
};
