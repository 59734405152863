
import { defineComponent } from 'vue';
import LogOutButton from '@/components/LogOutButton.vue';

export default defineComponent({
  name: 'SecuredView',
  components: { LogOutButton },
  data() {
    return {
      drawer: true,
    };
  },
});
