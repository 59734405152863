import { ThemeDefinition } from 'vuetify';

const rhbnbTheme: ThemeDefinition = {
  dark: false,
  colors: {
    'primary-darken-1': '#3498db',
    'secondary-darken-1': '#4eb96f',
    background: '#F3F4F8',
    surface: '#FFFFFF',
    primary: '#3498db',
    secondary: '#4eb96f',
    error: '#B00020',
    info: '#6ec0f7',
    success: '#83ce9a',
    warning: '#FB8C00',
  },
};

export default rhbnbTheme;
