
import { defineComponent } from 'vue';

import eventBus, { EVENTS } from '../event-bus';

const DEFAULT_OPTIONS = {
  timeout: 4000,
};

export default defineComponent({
  name: 'SnackBar',
  data: () => ({
    snackbar: false,
    text: '',
    options: {},
  }),
  mounted() {
    eventBus.$on(EVENTS.SHOW_SNACKBAR, (msg: string, options: Record<string, any>) => {
      this.snackbar = true;
      this.text = msg;

      this.options = {
        ...DEFAULT_OPTIONS,
        ...options,
      };
    });
  },
});
