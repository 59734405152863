import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';

import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import { languages } from './i18n';
import bus, { EVENTS } from './event-bus';

const i18n = createI18n({
  locale: 'es',
  fallbackLocale: 'en',
  messages: languages,
  allowComposition: true,
});

loadFonts();

const app = createApp(App)
  .provide('$showSnackbar', (msg: string, options?: Record<string, any>) => bus.$emit(EVENTS.SHOW_SNACKBAR, msg, options))
  .provide('$showLoadError', () => bus.$emit(EVENTS.SHOW_SNACKBAR, i18n.global.t('An error occurred while loading data')))
  .provide('$onForbidden', () => bus.$emit(EVENTS.FORBIDDEN_RESPONSE))
  .provide('$numberFormat', (value: number, fix = 2) => Number(value).toFixed(fix))
  .provide('$booleanFormat', (value: any) => (value ? i18n.global.t('Yes') : i18n.global.t('No')))
  .provide('$dateFormat', (value: Date) => {
    console.log('MM ', value);
    const mediumTime = new Intl.DateTimeFormat('es', {
      timeStyle: 'medium',
      dateStyle: 'short',
    });

    return mediumTime.format(value);
  })
  .use(router)
  .use(store)
  .use(vuetify)
  .use(i18n);

app.mount('#app');
