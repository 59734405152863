import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import * as security from '@/utils/security';
import UnsecureView from '@/views/UnsecureView.vue';
import SecuredView from '@/views/SecuredView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'unsecure',
    component: UnsecureView,
    children: [
      {
        path: '',
        name: 'home',
        component: () => import(/* webpackChunkName: "unsecure" */ '../views/HomeView.vue'),
      },
      {
        path: 'forbidden',
        name: 'forbidden',
        component: () => import(/* webpackChunkName: "unsecure" */ '../views/ForbiddenView.vue'),
      },
    ],
  },
  {
    path: '/sec',
    name: 'secure',
    component: SecuredView,
    children: [
      {
        path: 'templates',
        name: 'templates.list',
        component: () => import(/* webpackChunkName: "templates" */ '../views/TemplateListView.vue'),
      },
      {
        path: 'designs',
        name: 'designs.list',
        component: () => import(/* webpackChunkName: "templates" */ '../views/DesignListView.vue'),
      },
      {
        path: 'email/:id?',
        name: 'email.create',
        component: () => import(/* webpackChunkName: "email" */ '../views/EmailsView.vue'),
      },
      {
        path: 'members',
        name: 'member.list',
        component: () => import(/*  webpackChunkName: "member" */ '../views/MemberListView.vue'),
      },
      {
        path: 'members/create',
        name: 'member.create',
        component: () => import(/*  webpackChunkName: "member" */ '../views/MemberEditView.vue'),
      },
      {
        path: 'members/:id',
        name: 'member.update',
        props: { edit: true },
        component: () => import(/*  webpackChunkName: "member" */ '../views/MemberEditView.vue'),
      },
      {
        path: 'members/import-from-csv',
        name: 'member.import-from-csv',
        component: () => import(/*  webpackChunkName: "member" */ '../views/MemberCsvImportView.vue'),
      },
      {
        path: 'campaign/create',
        name: 'campaign.create',
        component: () => import(/*  webpackChunkName: "campaign" */ '../views/CampaignEditView.vue'),
      },
      {
        path: 'campaign/:id',
        name: 'campaign.update',
        props: { edit: true },
        component: () => import(/*  webpackChunkName: "campaign" */ '../views/CampaignEditView.vue'),
      },
      {
        path: 'campaign/:id/stats',
        name: 'campaign.stats',
        component: () => import(/*  webpackChunkName: "campaign" */ '../views/CampaignStatsView.vue'),
      },
      {
        path: 'campaign',
        name: 'campaign.list',
        component: () => import(/*  webpackChunkName: "campaign" */ '../views/CampaignListView.vue'),
      },
      {
        path: 'about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
      },
      {
        path: '',
        redirect: '/sec/campaign',
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// eslint-disable-next-line consistent-return
router.beforeEach(async (to, from) => {
  console.log(`from ${from.name?.toString()} to ${to.name?.toString()}`);
  console.log(`is logged ${security.isLogged()}`);

  if (
    !security.isLogged() && to.name !== 'home'
  ) {
    console.log('send to home page!');

    // redirect the user to the login page
    return { name: 'home' };
  }
});

export default router;
