import {
  OAuth2Client,
  generateCodeVerifier,
  OAuth2Fetch,
  OAuth2Token,
} from '@badgateway/oauth2-client';

import { OAUTH_CONFIG } from './constants';

const CODE_V_STORAGE_KEY = 'oauth_cv';
const TOKEN_STORAGE_KEY = 'oauth_token';

const getClient = () => new OAuth2Client({
  server: OAUTH_CONFIG.server,
  clientId: OAUTH_CONFIG.clientId,
  tokenEndpoint: OAUTH_CONFIG.tokenEndpoint,
  authorizationEndpoint: OAUTH_CONFIG.authorizationEndpoint,
});

export const authorize = async () => {
  const codeVerifier = await writeCodeVerifier();

  document.location = await getClient()
    .authorizationCode
    .getAuthorizeUri({
      redirectUri: OAUTH_CONFIG.redirectUri,
      codeVerifier,
      scope: ['SCOPE_NONE'],
      state: '-',
    });
};

export const getToken = async () => {
  const codeVerifier = popCodeVerifier();

  if (codeVerifier === null) {
    throw Error('Null code verifier');
  }

  const token = await getClient()
    .authorizationCode
    .getTokenFromCodeRedirect(document.location.toString(), {
      redirectUri: OAUTH_CONFIG.redirectUri,
      codeVerifier,
      state: '-',
    });

  await writeToken(token);
  return token;
};

export const refreshToken = async () => {
  const client = await getClient();

  const token = await client.refreshToken(readToken());
  await writeToken(token);

  return token;
};

export const fetchWrapper = new OAuth2Fetch({
  client: getClient(),
  getNewToken(): OAuth2Token | Promise<OAuth2Token | null> | null {
    return readToken();
  },
  async storeToken(token) {
    await writeToken(token);
  },
  async getStoredToken() {
    return readToken();
  },
  onError(err) {
    console.error(err);
  },
});

export const writeToken = async (token) => {
  localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(token));
};

export const readToken = () => {
  const stringToken = localStorage.getItem(TOKEN_STORAGE_KEY);
  return JSON.parse(stringToken || '{}');
};

export const popToken = () => {
  const stringToken = localStorage.getItem(TOKEN_STORAGE_KEY);
  localStorage.removeItem(TOKEN_STORAGE_KEY);

  return JSON.parse(stringToken || '{}');
};

export const writeCodeVerifier = async () => {
  const codeVerifier = await generateCodeVerifier();
  localStorage.setItem(CODE_V_STORAGE_KEY, codeVerifier);

  return codeVerifier;
};

export const popCodeVerifier = () => {
  const cv = localStorage.getItem(CODE_V_STORAGE_KEY);
  localStorage.removeItem(CODE_V_STORAGE_KEY);

  return cv;
};
