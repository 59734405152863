// Vuetify
import { createVuetify } from 'vuetify';

// Styles
import '@mdi/font/css/materialdesignicons.css';
import '../main.scss';
import rhbnbTheme from './theme';

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    defaultTheme: 'rhbnbTheme',
    themes: {
      rhbnbTheme,
    },
  },
});
