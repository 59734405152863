import emitter from 'tiny-emitter/instance';

export const EVENTS = {
  SHOW_SNACKBAR: 'SHOW_SNACKBAR',
  FORBIDDEN_RESPONSE: 'FORBIDDEN_RESPONSE',
  UNAUTHORIZED_RESPONSE: 'UNAUTHORIZED_RESPONSE',
  SW_UPDATE: 'SW_UPDATE',
  LOGOUT: 'LOGOUT',
};

export default {
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args),
};
