// eslint-disable-next-line no-shadow
export enum Headers {
  paginationTotal = 'x-pagination-total',
}

export const OAUTH_CONFIG = {
  server: process.env.VUE_APP_OAUTH_SERVER,
  clientId: process.env.VUE_APP_OAUTH_CLIENT,
  tokenEndpoint: '/oauth/token',
  authorizationEndpoint: '/oauth/authorize',
  redirectUri: process.env.VUE_APP_OAUTH_REDIRECT,
};
