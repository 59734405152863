import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_LogOutButton = _resolveComponent("LogOutButton")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_app_bar, {
      color: "primary",
      elevation: "1",
      prominent: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_app_bar_nav_icon, {
          variant: "text",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.drawer = !_ctx.drawer), ["stop"]))
        }),
        _createVNode(_component_v_toolbar_title, null, {
          default: _withCtx(() => [
            _createTextVNode("Campaigns")
          ]),
          _: 1
        }),
        _createVNode(_component_LogOutButton)
      ]),
      _: 1
    }),
    _createVNode(_component_v_navigation_drawer, {
      modelValue: _ctx.drawer,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.drawer) = $event)),
      rail: false,
      "expand-on-hover": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_list_item, {
          "prepend-avatar": "https://rentalho.com/assets/images/logo-rentalho.png",
          title: "RentalHo Campaigns",
          class: "pt-2",
          nav: ""
        }),
        _createVNode(_component_v_divider),
        _createVNode(_component_v_list, {
          density: "compact",
          nav: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list_item, {
              "prepend-icon": "mdi-chart-box",
              title: _ctx.$t('Campaigns'),
              value: _ctx.$t('Campaigns'),
              to: {name: 'campaign.list'}
            }, null, 8, ["title", "value", "to"]),
            _createVNode(_component_v_list_item, {
              "prepend-icon": "mdi-cards",
              title: _ctx.$t('Templates'),
              value: _ctx.$t('Templates'),
              to: {name: 'templates.list'}
            }, null, 8, ["title", "value", "to"]),
            _createVNode(_component_v_list_item, {
              "prepend-icon": "mdi-account-group-outline",
              title: _ctx.$t('Members'),
              value: _ctx.$t('Members'),
              to: {name: 'member.list'}
            }, null, 8, ["title", "value", "to"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_main, { class: "mt-7 mb-8" }, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    })
  ], 64))
}